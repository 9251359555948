.login-page{
  background-image: linear-gradient(135deg, #362479 10%, #801f8d 100%);
  width: 100%;
  height: 100vh;
  padding: 100px;
}

.box-form {
  display: none;
  margin: 0 auto;
  width: 80%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex: 1 1 100%;
  align-items: stretch;
  box-shadow: 0 2px 14px 4px #090b6f85;
}

.box-form .left {
  width: 50%;
  color: #FFFFFF;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://i.pinimg.com/736x/5d/73/ea/5d73eaabb25e3805de1f8cdea7df4a42--tumblr-backgrounds-iphone-phone-wallpapers-iphone-wallaper-tumblr.jpg");
  overflow: hidden;
}
.box-form .left .overlay {
  padding: 60px;
  width: 100%;
  height: 100%;
  background: #5961f9ad;
  overflow: hidden;
  box-sizing: border-box;
}
.box-form .left .overlay h1 {
  font-size: 80px;
  line-height: 1;
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 20px;
}
.box-form .left .overlay span p {
  margin-top: 30px;
  font-weight: 900;
}
.box-form .left .overlay span a {
  background: #3b5998;
  color: #FFFFFF;
  margin-top: 10px;
  padding: 14px 50px;
  border-radius: 100px;
  display: inline-block;
  box-shadow: 0 3px 6px 1px #042d4657;
}
.box-form .left .overlay span a:last-child {
  background: #1dcaff;
  margin-left: 30px;
}
.box-form .right {
  width: 50%;
  padding: 50px;
  overflow: hidden;
  background-color: white;
}
.box-form .right h5 {
  font-size: 40px;
  line-height: 0;
  margin-bottom: 40px;
  margin-top: 20px;
  color: #333;
}
.box-form .right p {
  font-size: 14px;
  color: #B0B3B9;
}
.box-form .right .inputs {
  overflow: hidden;
}
.box-form .right input {
  width: 90%;
  margin: 0 auto;
  padding: 20px 20px;
  margin-top: 25px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #B0B3B9;
  border-radius: 0;
}

.box-form .right input:focus {
  outline: 0 !important;
}

.box-form .right span {
  float: right;
  font-size: 14px;
}

.box-form .right button {
  color: #fff;
  font-size: 16px;
  padding: 12px 35px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  box-shadow: 0px 4px 20px 0px #7950a8a6;
  background-image: linear-gradient(135deg, #362479 10%, #801f8d 100%);
  margin: 40px 0;

  &:hover {
      background-image: linear-gradient(135deg, #564fac 10%, #454888 100%);
      cursor: pointer;
  }
}